<!-- 详情页 -->
<template>
  <div class="email-detail-page" v-if="myEmailInfo">
    <div class="rule-form" v-if="detailInfo">
      <van-cell class="title-box">
        <p class="title">收件详情</p>
      </van-cell>
      <van-cell>
        <p class="title">{{ detailInfo.subject }}</p>
      </van-cell>

      <div class="subject-box" v-if="!isShowFormItem">
        <div class="label link">
          {{ detailInfo.sendName }} &lt;{{ detailInfo.sendEmail }}&gt;
        </div>
        <div class="right link" @click="isShowFormItem = !isShowFormItem">
          展开
        </div>
      </div>
      <div class="form-content" v-else>
        <div class="form-item-0">
          <div class="left-label">发件人&nbsp;</div>
          <div class="right-value">
            <div class="label link">
              {{ detailInfo.sendName }} &lt;{{ detailInfo.sendEmail }}&gt;
            </div>
            <div class="right link" @click="isShowFormItem = !isShowFormItem">
              展开
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label">收件人&nbsp;</div>
          <div class="value">
            <el-tag v-for="(recieve, r) in detailInfo.recieves" :key="r">{{
              recieve.email
            }}</el-tag>
          </div>
        </div>
        <div class="form-item">
          <div class="label">抄送人&nbsp;</div>
          <div class="value" v-if="detailInfo.ccvos">
            <el-tag v-for="(ccov, r) in detailInfo.ccvos" :key="r">{{
              ccov.email
            }}</el-tag>
          </div>
        </div>
        <div class="form-item">
          <div class="label">时间&nbsp;</div>
          <div class="value">{{ detailInfo.sendTime }}</div>
        </div>
      </div>

      <div class="form-item-1">
        <div class="value">
          <iframe
            style="width: 100%; margin-top: 20px"
            id="article"
            frameborder="0"
            :style="{ height: iframeHeight + 'px' }"
          ></iframe>
        </div>
      </div>
      <div class="form-item" v-if="detailInfo && detailInfo.attachments">
        <div class="label">附件&nbsp;</div>
        <div class="value">
          <div
            class="file"
            v-for="(file, f) in detailInfo.attachments"
            :key="f"
          >
            <div class="icon-img">
              <img class="icon" fit="contain" :src="iconName(file)" />
            </div>
            <div class="file-name">{{ file.name }}</div>
            <div class="opt-btns">
              <a
                v-if="imgType(file)"
                :download="file.name"
                :href="file.url"
                target="_blank"
              >
                <i class="el-icon-view"></i>
              </a>
              <i class="el-icon-download" @click="downImg(file)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-buttons" v-if="detailInfo">
      <div class="item" v-if="detailInfo.isStar == 1">
        <van-icon
          @click="handleCancelStar(detailInfo.idCode)"
          name="star"
          class="icon on"
        />
        <span>取消星标</span>
      </div>
      <div class="item" v-else>
        <van-icon
          @click="addStar(detailInfo.unid)"
          name="star-o"
          class="icon"
        />
        <span>星标</span>
      </div>
      <div class="item">
        <van-icon name="records" @click="handleReply" class="icon" />
        <span>回复</span>
      </div>
      <div class="item">
        <van-icon name="share-o" @click="handleForward" class="icon" />
        <span class="name">转发</span>
      </div>

      <div class="item">
        <van-icon name="delete-o" @click="handelDelEmail" class="icon" />
        <span>删除</span>
      </div>
    </div>
  </div>
</template>

<script>
import urlblobDownload from "@/utils/download.js";

import { Dialog, Toast } from "vant";
import { emailInfo, handleStar, cancleStar, batchDelEmail } from "@/api/email";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      detailInfo: null,
      iframeHeight: 0,
      isShowFormItem: false,
    };
  },

  components: {},

  computed: {
    ...mapState("email", ["myEmailInfo", "groupAddress", "allAddress"]),

    unid() {
      return this.$route.query.unid;
    },
    isSender() {
      return this.$route.query.isSender || "";
    },
  },

  mounted() {
    this.getEmailInfo().then((res) => {
      if (this.myEmailInfo) {
        this.getEmail();
      }
    });
  },

  methods: {
    ...mapActions("email", [
      "getEmailInfo",
      "getGroupContactList",
      "getAllUsers",
    ]),
    getEmail() {
      emailInfo({ unid: this.unid, isSender: this.isSender })
        .then((res) => {
          let { data, code } = res;
          if (code == 200) {
            this.detailInfo = data;
            this.$nextTick(() => {
              this.viewArticle();
            });
          }
        })
        .catch((err) => {});
    },
    //iframe 保证样式隔离
    viewArticle() {
      const b = document.getElementById("article").contentWindow.document.body;
      b.style.margin = "0";
      b.style.overflowX = "auto";
      b.style.overflowY = "hidden";
      b.style.padding = "10px";
      //判断是否存在a标签，标签没有target
      let str = this.detailInfo.content;
      if (str) {
        var patt = /<a\b.*?<\/a>/g;
        var contextarr = str.match(patt);
        if (contextarr) {
          for (var i = 0; i < contextarr.length; i++) {
            //标签没有target
            if (contextarr[i].indexOf("target") == -1) {
              //item在字串中的位置
              var strindex = str.indexOf(contextarr[i]); //为a标签添加target属性
              var newresult = contextarr[i].replace(">", ' target="_blank">');
              var contextstr = str.substr(strindex, contextarr[i].length);
              str = str.replace(contextstr, newresult);
            }
          }
        }
      }
      b.innerHTML = str;

      setInterval(() => {
        this.iframeHeight = b.offsetHeight;
      }, 100);
    },
    addStar(code) {
      handleStar({ unid: code, isSender: this.detailInfo.isSender }).then(
        (res) => {
          if (res) {
            let { code, msg } = res;
            if (code == 200) {
              Toast.success(msg);
              this.getEmail();
            }
          }
        }
      );
    },
    handleCancelStar(code) {
      cancleStar({ idCode: code }).then((res) => {
        if (res) {
          let { code, msg } = res;
          if (code == 200) {
            Toast.success(msg);
            this.getEmail();
          }
        }
      });
    },
    handleReply() {
      this.$router.push({
        path: `/EmailReplyEmail`,
        query: { isSender: this.isSender, unid: this.unid },
      });
    },
    handleForward() {
      this.$router.push({
        path: `/forwardEmail`,
        query: { isSender: this.isSender, unid: this.unid },
      });
    },
    handelDelEmail() {
      Dialog.confirm({
        title: "提示",
        message: `<div><p style="font-size: 0.22rem;">确定删除选项中的邮件?</p><p style="font-size: 0.2rem;">删除后永久消失,无法恢复</p></div>`,
      })
        .then(() => {
          this.delEmail();
        })
        .catch(() => {
          // on cancel
        });
    },
    delEmail() {
      //	标记来源（RE_M收件箱,SE_M发件箱）

      const params = {
        isSender: this.detailInfo.isSender,
        unid: this.detailInfo.unid,
      };
      batchDelEmail([params]).then((res) => {
        if (res) {
          let { code, msg } = res;
          if (code == 200) {
            Toast.success(msg);
            this.$router.go(-1);
          }
        }
      });
    },
    iconName(folder) {
      switch (folder.suffix) {
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
          return folder.url;
        case "pdf":
          return require("@/assets/images/fileManager/pdf.png");
        case "ppt":
        case "pptx":
          return require("@/assets/images/fileManager/ppt.png");
        case "xls":
        case "xlsx":
          return require("@/assets/images/fileManager/xls.png");
        case "doc":
        case "docx":
        case "txt":
          return require("@/assets/images/fileManager/doc.png");
        case "rar":
        case "zip":
          return require("@/assets/images/fileManager/rar.png");
        default:
          return require("@/assets/images/fileManager/txt.png");
      }
    },
    imgType(folder) {
      switch (folder.suffix) {
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
        case "mp4":
          return true;

        default:
          return false;
      }
    },
    downImg(file) {
      urlblobDownload(file.url, file.name);
    },
  },
};
</script>
<style lang="scss" scoped>
.email-detail-page {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .rule-form {
    flex: 1;
    overflow-y: auto;
    .title-box {
      background: #f0f6ff;
    }
    .title {
      text-align: center;
    }
    .subject-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 16px;
      .label {
        flex: 1;
      }
      .right {
        width: 0.6rem;
      }
    }
    .form-item-0 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 16px;
      .left-label {
        width: 1.2rem;
        text-align: center;
      }
      .right-value {
        flex: 1;
        display: flex;
        flex-direction: row;
        .label {
          flex: 1;
        }
        .right {
          width: 0.6rem;
        }
      }
    }
    .form-item,
    .form-item-1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 16px;
      .label {
        width: 1.2rem;
        text-align: center;
      }
      .value {
        flex: 1;
        .file {
          background-color: rgba(126, 134, 142, 0.12);
          // width: 4rem;
          height: 0.72rem;
          font-size: 12px;
          margin: 0 0.2rem 0.25rem 0;
          border-radius: 2px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .icon-img {
            width: 0.5rem;
            height: 0.5rem;
            img {
              width: 0.5rem;
              height: 0.5rem;
            }
          }
          .file-name {
            max-width: 3rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .opt-btns {
            display: flex;
            flex-direction: row;
            width: 0.4rem;
            i {
              margin-left: 0.1rem;
              cursor: pointer;
              font-size: 12px;
            }
          }
        }
      }
    }
    .form-item-1 {
      padding: 0px;
    }
  }
  .footer-buttons {
    display: flex;
    width: 100%;

    padding-right: 0.3rem;
    width: 7.5rem;
    height: 1.04rem;
    background: #ffffff;
    box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: space-around;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .icon {
      font-size: 0.5rem;
      // margin-right: 0.3rem;
    }
    .on {
      color: gold;
    }
  }
  .link {
    color: #0094ee;
  }
}
</style>
